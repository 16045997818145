<template>
    <v-form ref="other_incomes">
        <form-section
            :sectionTitle="$t('applications.other-income')"
            wideFirstCol
        >
            <template v-slot:col-1>
                <div v-for="income in entitiesData" :key="income.id">
                    <control-tool-bar
                        :id="income.id"
                        :title="
                            $_.get(income, 'required', false)
                                ? $t('applications.income')
                                : $t('applications.other-income')
                        "
                        :type="
                            $_.get(income, 'required', false) ? 'current' : ''
                        "
                        :removeable="income.removeable"
                        @remove="removeEntity(income.id)"
                    ></control-tool-bar>
                    <applicant-other-income
                        :modelData="income"
                        @update="update(income.id, 'root', $event)"
                        :required="$_.get(income, 'required', false)"
                    ></applicant-other-income>
                </div>
                <v-layout class="mt-1" v-if="entitiesData.length < MAX_INCOMES">
                    <v-btn
                        color="secondary"
                        class="mb-2"
                        dark
                        @click="createEntity('other_income')"
                    >
                        <v-icon left>add_circle</v-icon
                        >{{ $t("applications.add-other-income") }}
                    </v-btn>
                </v-layout>
            </template>
        </form-section>
    </v-form>
</template>

<script>
import InteractsWithEntities from "@components/application/mixins/InteractsWithEntities";

export default {
    name: "applicant-other-incomes",
    components: {
        ApplicantOtherIncome: () => import("./ApplicantOtherIncome"),
        ControlToolBar: () => import("./ControlToolBar"),
    },
    mixins: [InteractsWithEntities],
    props: {},
    computed: {},

    data() {
        return {
            entityIdKey: "income.id",
            MAX_INCOMES: 4,
            name: this.$t("applications.other-incomes"),
        };
    },
    watch: {},
    methods: {
        validate() {
            return this.$refs["other_incomes"].validate();
        },
    },
};
</script>

<style scoped>
</style>